
import { defineComponent, computed, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import dayjs from 'dayjs'
import useOpenInfo from '@/hooks/openInfo'
import useSaveOpenInfo from '@/hooks/useSaveOpenInfo'
import useMutationCommon from '@/hooks/useMutationCommon'
import stepPercentage from '@/utils/number'
import { openFileView, setApptitle } from '@/utils/native-app'
import * as storage from '@/utils/storage'
import { useQuery } from '@vue/apollo-composable'
import { queryApi } from '@/graphql/query'

export default defineComponent({
  setup () {
    const router = useRouter()
    const form: any = reactive({
      customerStatus: [17, 18, 19, 20, 21, 22],
      customerChecked: false
    })
    const { openType, meta, step, title, saveCommonOfVuex } = useOpenInfo()
    // 查询字典
    const { result: dicRes } = useQuery(queryApi.findDictOpenAccount, {
      params: {
        markList: ['DISCLOSURE_CODE']
      }
    })
    const stateList = computed(
      () =>
        dicRes.value?.findDictOpenAccount.data.DISCLOSURE_CODE.filter(
          (item: { value: string }) => Number(item.value) >= 30 && Number(item.value) <= 35
        ) ?? []
    )
    const showPdf = () => {
      const url = `${process.env.VUE_APP_H5_STATIC_PAGE_URL}/static_pages/pdf/PIDeclarationForm(Individual_Corporate PI).pdf`
      const title = '专业投资者声明书（个人及法团)'
      const params = {
        fileType: 'pdf',
        fileUrl: url,
        title
      }
      openFileView(params)
    }
    const isDisabled = computed(() => {
      if (form.customerChecked) {
        return false
      }
      return true
    })

    // 保存下一步调用接口
    const saveNext = () => {
      const percentage = stepPercentage(step)
      const params = {
        formType: 'openAccount',
        meta: meta.value,
        content: JSON.stringify(form)
      }
      const common = {
        openType: openType.value,
        openStatus: 0,
        btnText: '继续开户',
        remark: `已完成${percentage}%，请继续完善资料`,
        step
      }
      useSaveOpenInfo(params).then(() => {
        useMutationCommon(common)
        saveCommonOfVuex({ info: common })
        const expireTime = dayjs(storage.get('captcha_expire_time'))
        const diff = dayjs().diff(expireTime, 'minute', true)
        // 如果在十分钟内 免验证
        if (diff < 10) {
          console.log(diff)
          router.push({ name: 'confirm' })
        } else {
          router.push({ name: 'captcha' })
          storage.remove('captcha_expire_time')
        }
      })
    }

    const handleNext = () => {
      saveNext()
    }

    onMounted(() => {
      setApptitle(title)
    })

    return {
      form,
      stateList,
      isDisabled,
      showPdf,
      handleNext
    }
  }
})
